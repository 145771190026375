<template>
  <div class="ChamberCenter">
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="所在地区:" prop="">
              <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
                <el-option :value="''" label="全部" />
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
              <div style="display: inline-block; width: 10px"></div>
              <el-select v-model="queryInfo.city" placeholder="请选择地区" @change="search()">
                <el-option :value="''" label="全部"></el-option>
                <el-option
                  v-for="item in operatingLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label=" " label-width="0px">
              <div class="input-with">
                <div>
                  <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd"
                    >新增</el-button
                  >
                  <!-- <el-button >批量导出</el-button> -->
                </div>
                <div>
                  <el-input
                    placeholder="请输入商会昵称"
                    v-model="queryInfo.chamberCommerceName"
                    class="input-with-select"
                  >
                    <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div class="tab_txt" v-loading="loading">
      <span style="position: relative; top: -10px">当前条件下共查处{{ total }}家商会</span>
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox v-model="item.checked1" label=""></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_left">
            <img :src="item.chamberCommerceLogo" alt="" />
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h5 style="display: inline-block; margin: 0; font-size: 18px">
                  {{ item.chamberCommerceName
                  }}<span class="span1" v-if="item.chamberCommerceId" @click="configureClick(item)">
                    <i size="14px" class="el-icon-document"></i>
                    已有邀约函
                  </span>
                  <span class="span2" v-else @click="configureClick(item)">
                    <i size="14px" class="el-icon-document"></i>
                    配置邀约函
                  </span>
                </h5>
              </div>

              <p class="address">
                <span> {{ item.province }}-{{ item.city }}</span>
              </p>
            </div>
          </div>
          <div class="storeCenter_item_top_right">
            <el-button class="buttom_right" type="primary" @click="handleAssociated(item)"
              >关联项目({{ item.projectCount }})</el-button
            >
            <el-button class="buttom_right" @click="Collections(item, 1)"
              >关联人员({{ item.personnelCount }})</el-button
            >
            <el-button class="buttom_right" @click="Collections(item, 2)"
              >关联企业({{ item.companyCount }})</el-button
            >
            <el-button class="buttom_right" @click="Collections(item, 3)"
              >指导单位({{ item.guidanceCount }})</el-button
            >
            <span class="edit" @click="editchamber(item)">编辑</span>
            <span class="frozen" @click="delchamber(item)">删除</span>
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getByFatherIdt, getProvince } from '@/api/demand'
import { commercelist, delcommercet } from '@/api/chambercenter'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  chamberCommerceName: null, //企业名称
  city: null, //所在地区
  province: null //所在省份
})
export default {
  name: 'ChamberCenter',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      operatingList: [],
      operatingLists: []
    }
  },
  created() {
    this.search()
    this.incity()
  },
  methods: {
    //点击配置邀请函
    configureClick(row) {
      if (row.chamberCommerceId) {
        //编辑
        this.$router.push({
          name: 'Configure',
          query: {
            id: row.id,
            chamberCommerceId: row.chamberCommerceId,
            title: row.chamberCommerceName
          }
        })
      } else {
        //新增
        this.$router.push({
          name: 'Configure',
          query: { id: row.id, chamberCommerceId: '', title: row.chamberCommerceName }
        })
      }
    },
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange() {
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          this.getByFatherIdt(o.code)
          this.search()
        } else if (this.queryInfo.province == '') {
          this.search()
        }
      })
    },
    //一览
    async search() {
      this.loading = true
      const res = await commercelist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    handleAdd() {
      this.$router.push({
        path: '/datament/addchamber'
      })
    },
    //编辑商会
    editchamber(item) {
      this.$router.push({
        name: 'addchamber',
        query: { id: item.id }
      })
    },
    handleAssociated(item) {
      this.$router.push({
        name: 'AssociatedItems',
        query: { id: item.id }
      })
    },
    //关联用户企业单位
    Collections(item, index) {
      this.$router.push({
        name: 'Collections',
        query: { id: item.id, flag: index }
      })
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    delchamber(row) {
      const ids = row.id
      this.$alert('您是否确认删除？')
        .then(function () {
          return delcommercet(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

h5 span {
  margin-left: 10px;
  font-weight: 400;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  color: #146aff;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 2px;
  background-color: #e8f1ff;
  cursor: pointer;

  i {
    margin-right: 6px;
  }
}

.span1 {
  opacity: 0.5;
}

.ChamberCenter {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 10px 0px 0px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;

    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }

    .storeCenter_item_top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .address {
        position: relative;
        top: 10px;
        left: -5px;
      }
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;

        img {
          width: 74px;
          height: 74px;
          border-radius: 4px;
          margin-right: 14px;
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;

            span {
              box-sizing: border-box;
              padding: 1px 6px;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #6090ee;
              color: #6090ee;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }
          .address {
            font-size: 14px;
            span {
              padding: 0px 10px;
            }
          }
        }
      }
      .storeCenter_item_top_right {
        .buttom_right {
          height: 36px;
          line-height: 1px;
        }
        .edit {
          padding-left: 20px;
          color: #4e93fb;
          cursor: pointer;
        }
        .frozen {
          padding-left: 20px;
          color: #fd5469;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
